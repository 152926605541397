.modal-title {
  font-size: 1.25rem;
  font-weight: 600;
}

.modal-header {
  border-bottom: 1px solid var(--mantine-color-gray-2);
}

.modal-body {
  padding: 0;
}
