.tinymce-react-default {
  height: 100%;
  width: 100%;

  border-radius: 10px;

  .tox.tox-tinymce {
    font-family: 'Manrope', sans-serif;
    border-radius: 10px;

    border: none;

    .tox-editor-container {
      border-radius: 10px;

      .tox-editor-header {
        /* Removed shadow from menubar of tinymce */
        border-radius: 10px 10px 0 0;
        box-shadow: none;
        border-bottom: 1px solid #d4d4d4;

        .tox-menubar {
          /* Remove outline of tinymce menubar menu button */
          .tox-mbtn:focus:not(:disabled) {
            box-shadow: none;
          }

          /* Set color of tinymce menubar menu button */
          .tox-mbtn:focus:not(:disabled)::after {
            box-shadow: none;
          }
          /* Set color of tinymce menubar menu button */
          .tox-mbtn.tox-mbtn--select.tox-mbtn--active {
            background-color: #d4d4d4;
          }
        }
      }

      .tox-sidebar-wrap {
        /* Change active editor border color */
        .tox-edit-area::before {
          border: none;
        }
      }
    }
  }
  /* Set color on hover of tinymce menubar item dropdown */
  .tox .tox-collection--list .tox-collection__item--active {
    background-color: #d4d4d4;
  }
  .tox-collection--list .tox-collection__item--active:hover {
    background-color: #d4d4d4;
  }
}
