.modal-footer {
  position: sticky;
  bottom: 0;
  padding: 16px 32px;
  background: white;

  border-top: 1px solid var(--mantine-color-gray-2);

  display: flex;
  justify-content: flex-end;
  align-items: center;
  gap: 12px;
  z-index: 10;
}
