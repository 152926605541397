.timeInputInput {
  border: 1px solid var(--mantine-color-gray-3);
  border-radius: 4px;
  overflow: hidden;
}
.timeInputLabel {
  color: var(--mantine-color-gray-5);
  font-weight: 700;
  font-size: 0.875rem;
  line-height: 1.5;
}
