.django-tinymce-default {
  & .tox.tox-tinymce {
    border: none !important;

    & .tox-statusbar {
      @apply hidden;
    }

    & .tox-toolbar__primary {
      @apply p-4 bg-gray-100 border-none rounded bg-none;
    }

    & .tox-edit-area {
      @apply mt-4 border-solid rounded min-h-156 border-1 border-default;
    }

    & .tox-toolbar__group {
      @apply flex items-center space-x-4;
    }

    & .tox-anchorbar {
      @apply hidden;
    }
  }
}
