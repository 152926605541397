/*
 *
 * Sample @font-face declaration
 * add fonts to `./fonts` and reference
 * in css using @font-face
 *
 * If using tailwind add font to `fonts`
 * object in tailwind.js to create util
 *
 */

/*
@font-face {
  font-family: "YourFontHere";
  font-style: normal;
  font-weight: normal;
  src: url("../fonts/your-font.woff2") format("woff2"),
       url("../fonts/your-font.woff") format("woff");
}
@font-face {
  font-family: "YourFontHere";
  font-style: normal;
  font-weight: bold;
  src: url("../fonts/your-font-bold.woff2") format("woff2"),
       url("../fonts/your-font-bold.woff") format("woff");
}
*/

.font-mono {
  @apply tabular-nums;
}
