.tinymce-react-default.comments-tinymce-editor-wrapper {
  .tox.tox-tinymce {
    @apply rounded-none;

    .tox-sidebar-wrap {
      @apply gap-24;

      .tox-edit-area {
        @apply mt-0 border-1 border-gray-300 border-solid rounded-[10px] !overflow-hidden;
      }

      .tox-sidebar {
        @apply border-gray-300 border-solid rounded border-1;
      }
    }
  }
}

.tinymce-react-default.tinymce-document-editor-edit {
  .tox.tox-tinymce {
    @apply rounded-none;
    .tox-editor-container {
      .tox-sidebar-wrap {
        @apply gap-24;

        .tox-edit-area {
          @apply mt-0 border-1 border-gray-300 border-solid rounded !overflow-hidden;
        }

        .tox-sidebar {
          @apply mr-24 border-gray-300 border-solid rounded border-1;
        }
      }

      .tox-editor-header {
        @apply border-b-0;
      }
    }
  }
}
