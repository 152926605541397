.root {
  .item {
    &::before {
      bottom: calc(var(--mantine-spacing-xl) * -4);
    }
  }

  .itemBullet {
    background-color: var(--mantine-color-gray-4);
    top: 50%;
  }
}
