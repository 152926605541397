.pagination {
  margin: 5px auto;
}

.pagination a {
  color: black;
  float: left;
  padding: 6px 16px;
  text-decoration: none;
  transition: background-color 0.3s;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin: 0 4px;
}

.pagination a.active {
  background-color: #1c5d79;
  color: white;
  border: 1px solid #1c5d79;
  border-radius: 4px;
}
