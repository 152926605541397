@import '@mantine/core/styles.css';
@import '@mantine/dates/styles.css';
@import '@mantine/notifications/styles.css';

@import 'base/*';
@import 'components/*';
@import 'tinymce-styles/**/*';
@import 'vendors/*';

body {
  font-family: 'Manrope', sans-serif;
}
