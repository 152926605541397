.root {
  &[data-size='sm'] {
    input[type='checkbox'] {
      width: 20px;
      height: 20px;
    }
  }

  &[data-size='md'] {
    input[type='checkbox'] {
      width: 32px;
      height: 32px;
    }
  }

  &[data-size='lg'] {
    input[type='checkbox'] {
      width: 48px;
      height: 48px;
    }
  }
}

.body {
  align-items: baseline;
}

.input {
  transition-duration: 100ms;
  background-color: white;
  border: 1px solid #9ca3af;
  border-radius: 0.125rem;
  transition-property: background-color, border-color;
  cursor: pointer;

  &:hover {
    background-color: #ecf4fa;
    border-color: #1c5d79;
  }

  &:active {
    background-color: #9dc1df;
    border-color: #1c5d79;
  }

  &:focus {
    background-color: #bfdbfe;
    border-color: #1c5d79;
    outline: none;
    box-shadow:
      0 0 0 2px white,
      0 0 0 4px #1c5d79;
  }

  &:checked {
    background-color: #1c5d79;
    border-color: #1c5d79;

    &:hover {
      background-color: #557c91;
    }

    &:active {
      background-color: #204152;
    }
  }
  &:indeterminate {
    background-color: #1c5d79;
    border-color: #1c5d79;

    &:hover {
      background-color: #557c91;
    }

    &:active {
      background-color: #204152;
    }
  }

  &:disabled {
    background-color: #e5e7eb;
    border-color: #d1d5db;

    &:checked {
      border-color: #d1d5db;
      background-color: #e5e7eb;
    }
  }

  &.error-checkbox {
    background-color: #fef2f2;
    border-color: #dc2626;
    box-shadow: 0 0 0 3px #fca5a5;

    &.checked {
      background-color: #dc2626;
    }
  }
}

.icon {
  height: 0;
  width: 8px;
}

.label {
  color: #1f2937;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-transform: none;
}

.error {
  color: #6b7280;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
  text-transform: none;
}
