input::placeholder,
textarea::placeholder {
  @apply font-light;
}

input[type='checkbox']:not([role='switch']):checked {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyIgZmlsbD0iI2ZmZiI+PHBhdGggZD0iTTkgMTYuMkw0LjggMTJsLTEuNCAxLjRMOSAxOSAyMSA3bC0xLjQtMS40TDkgMTYuMnoiLz48L3N2Zz4=');
  background-size: 75%;
}

input[type='checkbox']:not([role='switch']):checked:disabled {
  background-image: url('data:image/svg+xml;base64,PHN2ZyB2aWV3Qm94PSIwIDAgMjQgMjQiIHhtbG5zPSJodHRwOi8vd3d3LnczLm9yZy8yMDAwL3N2ZyI+PHBhdGggZD0iTTkgMTYuMkw0LjggMTJsLTEuNCAxLjRMOSAxOSAyMSA3bC0xLjQtMS40TDkgMTYuMnoiIGZpbGwtb3BhY2l0eT0iLjI0Ii8+PC9zdmc+');
}

input[type='checkbox']:not([role='switch']) {
  @apply bg-center;
}

input[type='checkbox'][role='switch'] {
  background-image: url(data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIHhtbG5zOnhsaW5rPSJodHRwOi8vd3d3LnczLm9yZy8xOTk5L3hsaW5rIiB2aWV3Qm94PSIwIDAgMjggMjgiPjxpbWFnZSB3aWR0aD0iMjgiIGhlaWdodD0iMjgiIG9wYWNpdHk9Ii4xNSIgeGxpbms6aHJlZj0iZGF0YTppbWFnZS9wbmc7YmFzZTY0LGlWQk9SdzBLR2dvQUFBQU5TVWhFVWdBQUFCd0FBQUFjQ0FZQUFBQnlEZCtVQUFBQUNYQklXWE1BQUFzU0FBQUxFZ0hTM1g3OEFBQUN2a2xFUVZSSVM3WFczVytVUlJRRzhOKzhiRVcwYVNqS0ZxVm9USlRJalJlWXhtdkNIODQxSVdDaUZ6YWdJVFFVK3FFQ1RhWHN0dTV3Y1daMjM5MXVsMjBibm1UeWZzMDd6enpubkRubnBKeXplWkJTU3ZXMjlUcERubmNScEZsekMwa2RDL2lrWEpNZ08wUy9YTFBnbmtsK0ltRktxUkdMZjFyR1pYU3hoQTZPc0ljZHZNYTdNZzV6em9OcGEzSUNZVXJwQWk3aEtxNExvdXY0SGl0Q2FSL2IrQk9iZ25nVHV6akFZSnJhWTRRdHNsWGN4cy80VnBDdk9LNXdXNUE4d3lQOGhoZllOMFZ0cC8zUUlydUJOZHdScE4zeS9xSnhIeTdqbWxEMFE1bTNqTi94QlAra2xQcHRwVVBDNHJPcWJBMTN5M1cxdkcrTUFxaWlFZWI5SEl0bGZDbUlvU2Y4KzMvOW9jTXdHaGZLeE50QzJacFErbGxaZUJycUJwb3k3MnRoQmNLbi82S1hVbnBiVFZzVk5tSjMzK0VYNGJkVnM4a20wUWl5TDNBVGYrT1Y4T1dMYXRwT1VkZkJsVEx4RnI1eU9yS0tKRXpjeFU5QzVZWlFlb2hjRjd3b25QOGp2aEZxVDB0VzBZaHoyeFhSM1MzUHFYNnMvbHN5Q3ZzYWlXZEZEY0Nyd3ErWHNaQktaREk5ZFowSFNWaHRSU1NMcmxqN21Obk9TMVJSNDJMSmVEcE1aL1hUbWZHeENMUHg1TDVYbnZNazRkeDFiUTcwQmRsZjVkcG5wSEJhYlRzUDZucHZSSExmRTRrOE42MlBlKzJQemtjNkVKdi9yNHkrc2w1VjJNTVcxa1ZtMkM4L25RVURVVDEyOEZ3azc2R0FwcFNPSTVGK0h1TVB2TVJicHljZGlQK2U0MWRSSHplRklJeVM5MENvZW9yN1J1ZW1uY0JubmRGc25Pd0I3Z25DWGNWL0ZNS2NjMDRwSFFvelBETGF5SkZJNUl0T3prQTFCdmFGWlI0S3NnZUMvS0JkOVljRk9PYzhTQ2tkbEVtTXp0RXRrZEJyanAxRURiZ040WTc3WXRPVmJGaDhtZDNUZEVWOXZDbXF5SXBSY1cyako2SjdYY1RBVTJHcFkyU2MzTFhWRm5GUjFNbHJXdm13TmJXZFViWkU0RTF0bmlwbTlhVzFkZWdZYjU0bVVYM1lFK1JUMjhPS21aMDNRMkkrSEtWenRmenZBUTE4R1VmTVFuR1pBQUFBQUVsRlRrU3VRbUNDIi8+PHBhdGggZD0iTTEzLjYxIDIuODlhMTAuNSAxMC41IDAgMDExMC41IDEwLjUgMTAuNSAxMC41IDAgMDEtMTAuNSAxMC41IDEwLjUgMTAuNSAwIDAxLTEwLjUtMTAuNSAxMC41IDEwLjUgMCAwMTEwLjUtMTAuNXoiIGZpbGw9IiNmZmYiLz48L3N2Zz4=);
}

input[type='time']::-webkit-calendar-picker-indicator,
input[type='date']::-webkit-calendar-picker-indicator,
input[type='datetime-local']::-webkit-calendar-picker-indicator {
  @apply z-10 text-transparent cursor-pointer rect-24;
  background: transparent;
}

/* Chrome, Safari, Edge, Opera */
input[type='number']::-webkit-outer-spin-button,
input[type='number']::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

/* Firefox */
input[type='number'] {
  -moz-appearance: textfield;
}

input[type='datetime-local'] {
  @apply min-w-250;
}
input[type='date'] {
  @apply min-w-180;
}
input[type='time'] {
  @apply min-w-136;
}

input[type='file'] {
  @apply relative p-4 -m-4;

  &[data-state='error']::file-selector-button {
    @apply ring-offset-2 ring-status-error ring-2;
  }

  &:focus-within::file-selector-button,
  &:focus::file-selector-button {
    @apply ring-offset-2 ring-link ring-2;
  }

  &::file-selector-button {
    @apply btn btn-sm mr-16 cursor-pointer !text-transparent;
    border: 1px solid rgb(229, 231, 235);
    /*
      This is a hack to change the button label. I'm hiding the default
      label and then manually applying the width based on updated icon
      and label.
    */
    width: 132px;

    /*
      Firefox doesn't support the pseudo ::before or ::after elements
      on this input field so we need to use the @supports rule to enable default styles for Firefox.
    */
    @supports (-moz-appearance: none) {
      @apply !text-primary;
    }
  }

  /* faked label styles and icon */
  &::before {
    @apply absolute pointer-events-none top-12 left-40 text-primary;
    content: 'Upload file';
  }

  &::after {
    @apply absolute pointer-events-none top-14 left-16 rect-20;
    content: '';
    background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24' fill='%230964B0'%3E%3Cpath d='M18 15v3H6v-3H4v3c0 1.1.9 2 2 2h12c1.1 0 2-.9 2-2v-3h-2zM7 9l1.41 1.41L11 7.83V16h2V7.83l2.59 2.58L17 9l-5-5-5 5z'/%3E%3C/svg%3E");
  }
}

select {
  background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' height='16px' width='16px' viewBox='0 0 24 24' fill='%231F2937'%3E %3Cpath d='M7 10L12 15L17 10H7Z'/%3E %3C/svg%3E ");
}
