@tailwind base;
@tailwind components;
@tailwind utilities;

@layer base {
  html {
    @apply antialiased scroll-smooth;
  }

  @media screen and (prefers-reduced-motion: reduce), (update: slow) {
    html {
      scroll-behavior: auto;
    }

    *,
    *::before,
    *::after {
      animation-duration: 0.001s !important;
      transition-delay: 0.001s !important;
      transition-duration: 0.001s !important;
    }
  }

  body {
    @apply overflow-x-hidden text-base text-primary-text scrollbar-default;
  }

  button,
  [role='button'],
  input[type='submit'] {
    &[disabled] {
      cursor: not-allowed;
    }
  }

  /* Eliminate outline for mouse users */
  html[data-whatintent='mouse'] *:focus,
  html[data-whatintent='keyboard'] *:focus,
  html[data-whatintent='touch'] *:focus {
    outline: none;
  }

  html[data-whatintent='keyboard'] *:focus {
    @apply ring;
  }

  svg {
    fill: currentColor;
  }

  table {
    border-spacing: 0;
  }

  th {
    text-align: left;
  }

  /* TODO: Should we apply thos styles for all links? */
  p {
    & a {
      @apply no-underline;
      @apply text-link;

      &:hover {
        @apply underline;
      }
    }
  }

  /* Hide arrow for details dropdown on safari when '.list-none' */
  details > summary.list-none::marker,
  details > summary.list-none::-webkit-details-marker {
    display: none;
  }

  /* Target iOS specifically */
  @supports (-webkit-touch-callout: none) {
    /* Target Safari 15 and older */
    @supports not (grid-template-columns: subgrid) {
      .scroll-shadow-vertical,
      .scroll-shadow-horizontal {
        background: none !important;
        background-size: initial !important;
      }
    }
  }

  .break-words {
    hyphens: auto;
  }

  :root {
    color-scheme: light !important;
  }
}
