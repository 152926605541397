.root {
  border: 1px solid var(--mantine-color-gray-2);
  border-radius: 8px;
  overflow: hidden;
}

.control {
  min-height: 48px;
  background-color: var(--mantine-color-white);

  &[data-active='true'] {
    background-color: var(--mantine-color-blue-0);
  }

  &[data-disabled='true'] {
    opacity: 1;
    background-color: var(--mantine-color-gray-0);
  }
}

.chevron {
  color: var(--mantine-primary-color-8);
  width: 1.5rem;
  height: 1.5rem;

  svg {
    width: 1.5rem !important;
    height: 1.5rem !important;
  }

  &[data-position='left'] {
    transform: rotate(-90deg);
  }

  &[data-position='right'] {
    transform: rotate(90deg);
  }

  &[data-rotate='true'] {
    transform: rotate(0deg);
  }
}

.item {
  border-color: var(--mantine-color-gray-2);

  &:last-child {
    border-bottom: none;
  }
}
