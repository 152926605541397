.inputWrapper {
  svg {
    display: none;
  }
}

.radio[type='radio']:checked {
  background-color: var(--mantine-primary-color-filled);
}

.body {
  align-items: baseline;
}
