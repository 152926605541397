/* Adapted from apexcharts: https://www.npmjs.com/package/apexcharts?activeTab=explore */

.charts-tooltip {
  opacity: 0;
  border-radius: 5px;
  box-shadow: 2px 2px 6px -4px #999;
  cursor: default;
  font-size: 14px;
  left: 62px;
  opacity: 0;
  pointer-events: none;
  position: absolute;
  display: flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  z-index: 12;
  transition: 0.15s ease all;
  transform: translate(-50%, -33.333%);

  @apply rounded-sm border-1 border-default;
}

.charts-tooltip.charts-active {
  opacity: 1;
  transition: 0.15s ease all;
}

.charts-tooltip.charts-theme-light {
  border: 1px solid #e3e3e3;
  background: rgba(255, 255, 255, 0.96);
}

.charts-tooltip.charts-theme-dark {
  color: #fff;
  background: rgba(30, 30, 30, 0.8);
}

.charts-tooltip * {
  font-family: inherit;
}

.charts-tooltip-title {
  padding: 6px;
  font-size: 15px;
  margin-bottom: 4px @apply !bg-white !border-none mb-0 text-secondary-text
    text-xs;
}

.charts-tooltip.charts-theme-light .charts-tooltip-title {
  background: #eceff1;
  border-bottom: 1px solid #ddd;
}

.charts-tooltip.charts-theme-dark .charts-tooltip-title {
  background: rgba(0, 0, 0, 0.7);
  border-bottom: 1px solid #333;
}

.charts-tooltip-text-goals-value,
.charts-tooltip-text-y-value,
.charts-tooltip-text-z-value {
  display: inline-block;
  margin-left: 5px;
  font-weight: 600;
}

.charts-tooltip-text-goals-label:empty,
.charts-tooltip-text-goals-value:empty,
.charts-tooltip-text-y-label:empty,
.charts-tooltip-text-y-value:empty,
.charts-tooltip-text-z-value:empty,
.charts-tooltip-title:empty {
  display: none;
}

.charts-tooltip-text-goals-label,
.charts-tooltip-text-goals-value {
  padding: 6px 0 5px;
}

.charts-tooltip-goals-group,
.charts-tooltip-text-goals-label,
.charts-tooltip-text-goals-value {
  display: flex;
}

.charts-tooltip-text-goals-label:not(:empty),
.charts-tooltip-text-goals-value:not(:empty) {
  margin-top: -6px;
}

.charts-tooltip-marker {
  width: 12px;
  height: 12px;
  position: relative;
  top: 0;
  margin-right: 10px;
  border-radius: 50%;
}

.charts-tooltip-series-group {
  padding: 0 10px;
  display: none;
  text-align: left;
  justify-content: left;
  align-items: center;
}

.charts-tooltip-series-group.charts-active .charts-tooltip-marker {
  opacity: 1;
}

.charts-tooltip-series-group.charts-active,
.charts-tooltip-series-group:last-child {
  padding-bottom: 4px;
}

.charts-tooltip-series-group-hidden {
  opacity: 0;
  height: 0;
  line-height: 0;
  padding: 0 !important;
}

.charts-tooltip-y-group {
  padding: 6px 0 5px;
}

.charts-custom-tooltip,
.charts-tooltip-box {
  padding: 4px 8px;
}

.charts-tooltip-boxPlot {
  display: flex;
  flex-direction: column-reverse;
}

.charts-tooltip-box > div {
  margin: 4px 0;
}

.charts-tooltip-box span.value {
  font-weight: 700;
}

.charts-tooltip-rangebar {
  padding: 5px 8px;
}

.charts-tooltip-rangebar .category {
  font-weight: 600;
  color: #777;
}

.charts-tooltip-rangebar .series-name {
  font-weight: 700;
  display: block;
  margin-bottom: 5px;
}

.charts-tooltip-text-y-label {
  @apply text-xs font-normal text-primary-text;
}

.charts-tooltip-text-y-value {
  @apply text-xs font-semibold text-primary-text;
}
