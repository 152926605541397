.root {
  .list {
    &::before {
      border: none;
    }
  }

  .tab {
    font-size: 16px;
    &::before,
    &::after {
      background-color: var(--mantine-color-gray-0);
    }

    &[data-active] {
      border-color: var(--mantine-color-gray-2);
      border-bottom-color: var(--mantine-primary-color-8);
      .tabLabel {
        color: var(--mantine-primary-color-8);
      }
      .tabSection {
        color: var(--mantine-primary-color-8);
      }
    }

    &[data-variant='pills'][data-active] {
      .tabLabel {
        color: white;
      }
    }

    &[data-variant='outline'][data-active] {
      background-color: var(--mantine-color-gray-0);
      border-bottom-color: var(--mantine-color-gray-0);
    }
  }

  &[data-variant='outline'] {
    .panel {
      background-color: var(--mantine-color-gray-0);
    }
  }
}
