.root {
  background-color: var(--mantine-color-gray-0);
}

.placeholder {
  color: var(--mantine-color-gray-5);
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 150%;
}
